import React from 'react';
import {Link} from 'react-router-dom';
import LOGO from "../img/rh-new-logo.svg";

const PaymentSuccessfulComponent = (props) => {
    return(
        <Holder>
            <div style={{padding:"10px",margin:"10px",backgroundColor:"white",color:"#111"}}>
                <div>
                    <h2>Welcome Back!!</h2>
                    <p>
                        Your payment was successful and your plan has been renewed.
                        <br/>
                        For enquiries, please don't hesitate to send a mail to <a href="mailto:hellonigeria@getreliancehealth.com?Subject=Guardian Enquiry [from web app]">hellonigeria@getreliancehealth.com</a>
                        <br/>
                        Thanks for choosing RelianceHMO.
                    </p>
                    <br/>
                    <br/>
                    <div style={{fontSize:"22px"}}>
                        <div style={{float:"left"}}>
                            <Link to="/">Continue</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Holder>
    )
};

export const Holder = (props) => (
    <div className="container">
        <div className="signup">
            <div className="signup__header">
                <img src={LOGO} alt="logo" style={{maxWidth: '20rem'}}/>
                <p>&nbsp;| For Guardians</p>
            </div>
            <div className="signup__form">
                {props.children}
            </div>
        </div>
    </div>
);

export default PaymentSuccessfulComponent;
