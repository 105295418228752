import React from 'react';
import LOGO from '../../img/rh-new-logo.svg';

const FormHolder = (props) => {
  return (
    <div className="dashboard" style={{height: "100%"}}>
        <div className="login_form">

            <img src={LOGO} alt="RelianceHMO's Logo"  style={{maxWidth:'20rem'}}/>

            { props.isLogin && <p className="dashboard-card__body-text">To access to all your dashboards,<br />
                Login with your RelianceHMO account </p>}
            
            { props.message && <div style={{padding:"10px",backgroundColor:"red",color:"white",visibility:(!props.isLoading && props.message)?"visible":"hidden"}}>
              {props.message}
              </div> }

            {props.children} 

      </div>
    </div>
  )
};

export const MessageHolder = (props) => {
  return (
    <div style={{display: "grid", padding:"10px",margin:"0 auto", width: "90%", height: "50%", alignItems: "center", backgroundColor:"white",color:"#111"}}>
      <div>
        {props.children}
      </div>
    </div>
  )
};

export default FormHolder;
