import React from 'react';
import queryString from 'querystring';
import * as userActions from '../actions/user';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {Link, withRouter, Redirect} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import LoginForm from '../components/forms/LoginForm';
import FormHolder, { MessageHolder } from '../components/boxes/FormHolder';
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassword:queryString.parse(props.location.search.substr(1)).fp || false,
      referrer: queryString.parse(props.location.search.substr(1)).r || '',
      resetMethod: null
    }
  }

  renderLoading = () => {
    return <LoadingScreen/>
  };

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(userActions.setUserLoading(false));
  }

  onFormSubmit = (values, dispatch) => {
    const { forgotPassword, referrer } = this.state;
    if(forgotPassword){
      const source = window.location.origin + window.location.pathname;
      const payload = {
        ...values,
        redirect_url: decodeURI(referrer) || source,
        source_url: source
      }
      this.setState({resetMethod: values.type})
      dispatch(userActions.resetPassword(payload));
    }else{
      dispatch(userActions.loginUser(values.email, values.password, values.remember_me));
    }
  };

  renderLogin = () => {
    const {isLoading, error} = this.props;
    const {forgotPassword} = this.state;
    return(
      <FormHolder isLogin message={error && error.message} isLoading={isLoading}>
        <LoginForm 
          onSubmit={this.onFormSubmit} 
          checked={this.checked} 
          />
        <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
      </FormHolder>
    )
  };

  toggleForm = (e) => {
    e.preventDefault();
    this.setState({
      forgotPassword:!this.state.forgotPassword
    });
  };

  renderForgotPassword =() => {
    const {isFetching, error} = this.props;
    const {forgotPassword} = this.state;
    return (
      <FormHolder message={error && error.message} isLoading={isFetching}>
        <ForgotPasswordForm onSubmit={this.onFormSubmit}/>
        <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
      </FormHolder>
    )
  };

  renderPasswordResetSuccessfulMessage = () => {
    const { recoveryMessage } = this.props
    return (
      <FormHolder>
        <MessageHolder>
          <h2 style={{marginBottom: "10px"}}>Reset Password Instructions Sent</h2>
          <p>{recoveryMessage}</p>
        </MessageHolder>
      </FormHolder>
    )
  };

  render() {
    const {isFetching, checked, authenticated, user, recoveryMessage} = this.props;

    const {forgotPassword, referrer, resetMethod} = this.state;

    let from = "/welcome";

    if (referrer) {
      from = decodeURI(referrer);
    }

    if (isFetching) return this.renderLoading();

    if (checked && authenticated && !_.isEmpty(user)) {
      //check if referrer is dashboard,
      // if yes, check if user can access dashboard,
      // if yes...proceed,else go to /dashboard/enrollees
      window.location.replace(from);
      return null;
    }

    if (recoveryMessage && resetMethod === RESET_METHODS.email) return this.renderPasswordResetSuccessfulMessage();

    if (recoveryMessage && resetMethod === RESET_METHODS.phone) {
      return <Redirect
        to={{
          pathname: "/reset-password/verify",
          state: {
            message: recoveryMessage,
            resetMethod
          }
        }}
      />
    }

    if (forgotPassword) return this.renderForgotPassword();

    return this.renderLogin();
  };
}

const RESET_METHODS = {
  email: '1',
  phone: '2'
}

const PageExtras = (props) => {
  return (
    <div>
      <p className="dashboard-card__body-text">
        {props.isForgotPassword ? "" : "Forgot password?"}
        {" "}
        <span className="mini_cta">
            <Link to={'#'} onClick={props.toggleForm} className="dashboard-card__link-item">
                {props.isForgotPassword ? "Back" : "Click here" }
            </Link>
        </span>
      </p>
    </div>
  )
};

function mapStateToProps(state) {
  const {isFetching, error, recoveryMessage} = state.user || {};
  const {checked, authenticated, user} = state.session || {};
  return {isFetching, error, checked, authenticated, user, recoveryMessage };
}

export default withRouter(connect(mapStateToProps)(LoginComponent));
